import React from 'react'
import { Link } from 'gatsby'

const ServicesMenu = (props) => (
  <div>
  <h2 className="major">Products & Services</h2>
  <ul className="bigList">
  <li className={props.pageName === 'screenprint' ? 'current' : ''}><Link to="/services/screenprint">Screenprinting</Link></li>
  <li className={props.pageName === 'giclee' ? 'current' : ''}><Link to="/services/giclee">Giclee Printing</Link></li>
  <li className={props.pageName === 'offset' ? 'current' : ''}><Link to="/services/offset">Offset Lithography</Link></li>
  <li className={props.pageName === 'design' ? 'current' : ''}><Link to="/services/design">Design</Link></li>
  <li className={props.pageName === 'tourposters' ? 'current' : ''}><Link to="/services/tourposters">Tour Posters</Link></li>
  <li className={props.pageName === 'merch' ? 'current' : ''}><Link to="/services/merchandise">Merch</Link></li>
  </ul>
  </div>
)

export default ServicesMenu
