import React from 'react'
import {Link} from 'gatsby'
import Layout from '../../components/layout'
import ServicesMenu from './_menu.js'

const ScreenprintPage = () => (
  <Layout bgClass="services">

    <ServicesMenu pageName="screenprint" />

    <div className="textPageDetails">
    <p>
      To inquire about getting a project printed by Nakatomi, <Link to="/contact">contact us</Link> with a preview image of the print, quantity, screen (color) count, and any specialty instructions (metallic ink, specialty paper, etc). Minimum print orders are 50 copies for &lt; 18"x24" and 100 copies for &lt; 20"x30".
    </p>

    <h2>PAPER</h2>

<p>
    Nakatomi stocks 100lb Cougar White and Cougar Natural cover stock, which has a smooth, ideal poster surface. If the project calls for specialty paper, the customer may ship their own or invoice paper through Nakatomi. All invoices with specialty paper must be paid up front.
</p><p>
    <h2>FILE FORMAT</h2>
    </p><p>
    300dpi layered .tiff files are preferred, with each color on its own transparent layer and each layer labeled with its Pantone number (Coated (C) and Uncoated (U) guides).  Color matching to the screen without Pantone guide numbers is possible, but not advised. One color per layer except in cases with a gradient or split fountain effect. Notes on ink transparency/overlay/color are highly recommended.
  </p><p>

    If the image needs to be full bleed, set up file up with 1/4" bleed all the way around. However, the largest size prints–20"x30"–need at least a 3/4" paper (no ink) border all the way around the print. Smaller sizes can be full bleed.
  </p>

    <h2>PRICING</h2>

    <p>
        All pricing based on using 100lb Cover Cougar White or Natural stock paper. Specialty paper requests affect pricing.
    </p>


      <h2>Up to 18×24</h2>

      <table className="simpleTable">
      <tr className="header"><td># colors</td><td>60-100 prints</td><td>100 prints or more</td></tr>
      <tr><td>1 color  </td><td>       $2.70    </td><td>      $2.10</td></tr>
      <tr><td>2 colors </td><td>       $3.65    </td><td>      $3.05</td></tr>
      <tr><td>3 colors </td><td>       $4.60    </td><td>      $4.00</td></tr>
      <tr><td>4 colors </td><td>       $5.55    </td><td>      $4.95</td></tr>
      <tr><td>5 colors </td><td>       $6.50   </td><td>       $5.90</td></tr>
      <tr><td>6 colors </td><td>       $7.45   </td><td>       $6.85</td></tr>
      <tr><td>7 colors </td><td>       $8.40   </td><td>       $7.80</td></tr>
      <tr><td>8 colors </td><td>       $9.35   </td><td>       $8.75</td></tr>
      </table>

      <h4>Details</h4>
      <ul>
        <li>Printable Image area is 18×24.</li>
        <li>Screen/Film charges - $30 per color.</li>
        <li>Color Change (to use same screen with a new color / variant) - $20 per color / screen.</li>
        <li>Split/Blend - 1.00 per layer, per print extra.</li>
        <li>Glow - $1.25 per layer per print extra.</li>
        <li>Packing- $15 per box. We have a 100% safe shipment rate regarding bent corners and dropped boxes.</li>
      </ul>

      <h2>Up to 20×30</h2>

      <table className="simpleTable">
      <tr className="header"><td># colors</td><td>  100 prints or more</td></tr>

      <tr><td>1 color   </td><td>    $3.45</td></tr>
      <tr><td>2 colors  </td><td>    $4.90</td></tr>
      <tr><td>3 colors  </td><td>    $6.35</td></tr>
      <tr><td>4 colors  </td><td>    $7.80</td></tr>
      <tr><td>5 colors   </td><td>   $9.25</td></tr>
      <tr><td>6 colors   </td><td>   $10.70</td></tr>
      <tr><td>7 colors   </td><td>   $12.15</td></tr>
      <tr><td>8 colors  </td><td>    $13.60</td></tr>
      </table>

      <h4>Details</h4>
      <ul>
        <li>Printable Image area - for 20×30 prints.</li>
        <li>Screen/Film charges - $35 per color.</li>
        <li>Color Change (to use same screen with a new color / variant) - $25 per color / screen.</li>
        <li>Split/Blend - 1.50 per layer, per print extra.</li>
        <li>Glow - $2.00 per layer per print extra.</li>
        <li>Packing- $25 per box. We have a 100% safe shipment rate regarding bent corners and dropped boxes.</li>
      </ul>
    </div>
  </Layout>
)

export default ScreenprintPage
